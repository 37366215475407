@import "../../Core/Style/Variables";

button.ui.button.logout-button {
  width: 100px;
  margin: 0 auto;
  display: block;
  background-color: #1e1e62cf;
  color: $color__fill-white;
}

// .ui.grid{
//     margin: 0 !important;
// }
.ui.grid>.column:not(.row),
.ui.grid>.row>.column {
  padding-left: 15px;
  padding-right: 15px;
}

body {
  // background: #f5f8fe;
  background: $body-bg;
  //   background: #000;
}

.header-review {
  margin-bottom: 30px;
}

.view-report-section {
  background: #dededf;
  border-radius: 15px;

  .progress-bar-user {
    padding: 22px;

    .your-scrore-count {
      color: #3f51b5;
      font-weight: 600;
      font-size: 40px;
    }

    .score-bar {
      margin-bottom: 5px;

      .bar {
        background: $color__fill-white;
        height: 10px;
      }
    }
  }

  .detail-user {
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    padding: 22px;
    background-color: rgb(212, 198, 198);
  }
}

.dashboard-page {
  // padding-top: 133px;
  // margin-left: 225px;
  height: calc(100vh - 0px);
  overflow-y: auto;
  overflow-x: hidden;

  .active__game-tab {
    background-color: transparent !important;
    border-radius: 0px;
    border-radius: 0px;
    color: #1b75bc !important;
    border-bottom: 2px solid;
    margin-bottom: -2px;
    font-size: 18px;
  }

  .inactive__game-tab {
    background-color: transparent !important;
    border-radius: 0px;
    color: #aaaeb1 !important;
    font-size: 18px;
  }

  .game__tab-row {
    margin: 0 0 20px 0;
    border-bottom: 2px solid #ebebeb;
    display: flex;
  }

  .yellow-button {
    background: linear-gradient(#f4cc23, #9b7f0c) !important;
    border-radius: 20px !important;
    color: #333 !important;
  }

  .vt-center {
    align-self: center;
  }

  .vt-bottom {
    align-self: flex-end;
  }
  .text-align{
    text-align: center;
  }

  .opted-program {
    h2 {
      margin: 18px 0;
    }
  }

  p {
    font-weight: 400;
    font-size: 18px;
    color: #4c4c4c;
  }

  // .ui.secondary.menu .dropdown.item:hover, .ui.secondary.menu .link.item:hover, .ui.secondary.menu a.item:hover{
  //     background: #cce0f0 !important;
  // }
  .ui.secondary.menu a.item:hover {
    background: #cce0f0 !important;
  }

  .assesment-test_sec {
    background: #F7FAFF;
    border-radius: 4px;
    padding: 22px;
    box-shadow: 3px 2px 4px #f1f1f1;
  }

  .take-test_btn {
    margin-top: 30px;
    background-color: $primary_color  !important;
    color: $color__fill-white  !important;
    border: 2px solid $primary_color;

    i {
      color: $color__fill-white  !important;
      opacity: 1 !important;
    }
  }
  .student-research_sec{
    box-shadow: 3px 2px 4px #f1f1f1;
  }

  .upcoming-meeting-bg {
    background: #e5eff7;
  }

  .take-test_btn::before {
    background-color: $color__fill-primary  !important;
    color: $color__fill-white  !important;
  }

  .take-test_btn::after {
    background-color: $color__fill-primary  !important;
    color: $color__fill-white  !important;
  }

  .grey-img-assessment {
    margin: 0;
    max-height: 259px;
    object-fit: contain;
  }

  .threesixty__logo {
    width: 98px;
    margin: auto;
  }

  .send-msg-btn {
    background-color: $color__fill-white  !important;
    color: $primary_color  !important;
    border: 2px solid $primary_color  !important;
    // margin-left: 17px;
    // float: right;
  }

  .learn-more_link {
    color: $color__fill-secondary;
    background: transparent;
    text-decoration: underline;
  }

  .learn-more_link:hover {
    color: $color__fill-secondary  !important;
    background-color: transparent !important;
  }

  .user-active {
    height: 18px;
    border: 2px solid $color__fill-white;
    width: 18px;
    background-color: $color__active-green;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    right: 60px;
    top: 0px;
  }

  .grey-heading {
    color: $color__fill-black;
    font-weight: 600;
    margin-bottom: 14px !important;
    margin-top: 5px !important;
  }

  .h-fix {
    height: 425px;
    overflow: auto;
  }

  .light-bg {
    background: #cce0f0;
  }

  .outline-input {
    margin: 0 0 0 10px;
    border: none !important;
    border-bottom: 1px solid #c8c5c5 !important;

    &:focus-visible {
      outline: none;
      box-shadow: none;
    }
  }
}

.assesment-test_sec, .dashboard-page-udc {
  // padding-top: 133px;
  // margin-left: 225px;
  // height: calc(100vh - 0px);
  // overflow-y: auto;
  // overflow-x: hidden;

  .active__game-tab {
    background-color: transparent !important;
    border-radius: 0px;
    border-radius: 0px;
    color: #1b75bc !important;
    border-bottom: 2px solid;
    margin-bottom: -2px;
    font-size: 18px;
  }

  .inactive__game-tab {
    background-color: transparent !important;
    border-radius: 0px;
    color: #aaaeb1 !important;
    font-size: 18px;
  }

  .game__tab-row {
    margin: 0 0 20px 0;
    border-bottom: 2px solid #ebebeb;
    display: flex;
  }

  .yellow-button {
    background: linear-gradient(#f4cc23, #9b7f0c) !important;
    border-radius: 20px !important;
    color: #333 !important;
  }

  .vt-center {
    align-self: center;
  }

  .vt-bottom {
    align-self: flex-end;
  }
  .text-align{
    text-align: center;
  }

  .opted-program {
    h2 {
      margin: 18px 0;
    }
  }

  p {
    font-weight: 400;
    font-size: 18px;
    color: #4c4c4c;
  }

  // .ui.secondary.menu .dropdown.item:hover, .ui.secondary.menu .link.item:hover, .ui.secondary.menu a.item:hover{
  //     background: #cce0f0 !important;
  // }
  .ui.secondary.menu a.item:hover {
    background: #cce0f0 !important;
  }

  .assesment-test_sec {
    background: #F7FAFF;
    border-radius: 4px;
    padding: 22px;
    box-shadow: 3px 2px 4px #f1f1f1;
  }

  .take-test_btn {
    margin-top: 30px;
    background-color: $primary_color  !important;
    color: $color__fill-white  !important;
    border: 2px solid $primary_color;

    i {
      color: $color__fill-white  !important;
      opacity: 1 !important;
    }
  }
  .student-research_sec{
    box-shadow: 3px 2px 4px #f1f1f1;
  }

  .upcoming-meeting-bg {
    background: #e5eff7;
  }

  .take-test_btn::before {
    background-color: $color__fill-primary  !important;
    color: $color__fill-white  !important;
  }

  .take-test_btn::after {
    background-color: $color__fill-primary  !important;
    color: $color__fill-white  !important;
  }

  .grey-img-assessment {
    margin: 0;
    max-height: 259px;
    object-fit: contain;
  }

  .threesixty__logo {
    width: 98px;
    margin: auto;
  }

  .send-msg-btn {
    background-color: $color__fill-white  !important;
    color: $primary_color  !important;
    border: 2px solid $primary_color  !important;
    // margin-left: 17px;
    // float: right;
  }

  .learn-more_link {
    color: $color__fill-secondary;
    background: transparent;
    text-decoration: underline;
  }

  .learn-more_link:hover {
    color: $color__fill-secondary  !important;
    background-color: transparent !important;
  }

  .user-active {
    height: 18px;
    border: 2px solid $color__fill-white;
    width: 18px;
    background-color: $color__active-green;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    right: 60px;
    top: 0px;
  }

  .grey-heading {
    color: $color__fill-black;
    font-weight: 600;
    margin-bottom: 14px !important;
    margin-top: 5px !important;
  }

  .h-fix {
    height: 425px;
    overflow: auto;
  }

  .light-bg {
    background: #cce0f0;
  }

  .outline-input {
    margin: 0 0 0 10px;
    border: none !important;
    border-bottom: 1px solid #c8c5c5 !important;

    &:focus-visible {
      outline: none;
      box-shadow: none;
    }
  }
}

.main-content {
  // margin-top: 100px;
  margin-right: 40px;
  margin-left: 40px;
  margin-bottom: 100px;
}

.enable-take-test {
  background: transparent;
  padding: 25px;
  border-radius: 20px;

  p {
    font-size: 16px;
  }
}

.enable-take-test-sec {
  background: transparent;
  padding: 9px;
  border-radius: 20px;

  p {
    font-size: 16px;
  }
}

.dashboard-page-small {
  margin-left: 50px !important;
  padding-top: 88px;
  height: calc(100vh - 0px);
  overflow-y: auto;
  overflow-x: hidden;
}

.admin-txt {
  color: $primary_color  !important;
  cursor: pointer;
}

// .game__dashboard_box-container {
//   text-align: center;
//   // &:hover {
//   //   i {
//   //     opacity: 1;
//   //     cursor: pointer;
//   //   }
//   // }

//   img {
//     border-radius: 8px;
//     box-shadow: #1b75bc 0px 5px 15px;
//   }

//   .playbutton {
//     position: absolute;
//     top: 43%;
//     left: 50%;
//     font-size: 50px;
//     right: 50%;
//     color: $color__fill-black;
//     background: linear-gradient(#ecbd1b, #bea628) !important;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     transform: translate(-50%, -50%);
//     padding: 30px 0;
//     border-radius: 100%;
//     opacity: 1;
//     transition: opacity 0.5s linear;
//     cursor: pointer;
//   }
// }

// .video_view-link{
//   // float: right;
//   margin: 4px 0 18px 0;
//   color: #1b75bc;
//   font-weight: bold;
//   text-decoration: underline;
//   cursor: pointer;
//   line-height: 3;
// }

// .threesixty__logo{
//   width: 80%;
// }

.yellow-button {
  background: linear-gradient(#f4cc23, #9b7f0c) !important;
  border-radius: 20px !important;
  color: #333 !important;
}

.text-align{
  text-align: center;
}