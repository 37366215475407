@import "../../../Core/Style/Variables.scss";
body {
  background: $body-bg;
}
.user-question-page-inner{
    margin-left: 210px !important;
}
.user-question-page{
    padding-top: 164px;
    margin-left: 50px;
    // height: calc(100vh - 0px);
    // overflow-y: auto;
    // overflow-x: hidden;
    .main-content{
        margin-right: 40px;
        margin-left: 40px;
        margin-bottom: 100px;
    }
    .user-question-stepper {
        display: flex;
        align-items: center;
    }
    .form-question {
        margin-top: 24px;
        background: #ffffff;
        padding: 1px 0 0 0;
    }
    .btn-sm{
        background-color: #1b75bc !important;
        color: #fff !important;
        padding: 5px 8px;
        border-radius: 5px;
        font-weight: bold;
        background-image: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0.3) 50%, rgba(255, 255, 255, 0.2));
        border-radius: 20px;
    }
}
.yallo-button-for-next{
    background-color: $color__fill-white;
    padding: 25px;
    border-radius: 7px;
    p {
        font-size: 12px;
        font-weight: 800;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        min-height: 35px;
    }
    .first-category-question{
        .disabled.fifteen.wide.field{
            opacity: 1;
            label{
                opacity: 1;
            }
            select{
                opacity: .45;
            }
        }
        .disabled{
            opacity: .45;
        }
    }
    .yellow-button {
        // position: absolute;
        // bottom: -22px;
        // display: flex;
        padding: 11px 10px;
        margin-bottom: 3%;
        // width: 6%;
    }
    .form-question{
        margin-top: 24px;
        background: #ffffff;
        padding: 1px 0 0 0;
    }
    .progress.progress-bar-question-list{
        height: 12px;
        border-radius: 50px !important;
        margin-top: -22px;
        background: #cbd0d5;
        .bar{
            background-color: #267bbf;
            height: 12px;
            border-radius: 50px !important;
        }
    }

}
.mcq-question-label{
    font-weight: 400;
    font-size: 16px;
    color: #4c4c4c;
    -webkit-user-select: none;
        -webkit-touch-callout: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        // color: #cc0000;

}

.option{
    padding: 0 1rem;
    font-size: 14px;
}
.mcq-modrate-question{
    display: flex;
    align-items: center;
    width: 75%;
    margin: auto;
    position: relative;

    .mcq-question{
        width: 100% !important;
        padding-left: 2px !important;
        padding-right: 10px !important;
    }
}
.category-section-1{
    width: 60% !important;
}
.category-cd-section-2{
    width: 60% !important;
}
.category-section-3{
    width: 70% !important;
}
.category-section-4{
    width: 42% !important;
}

.label-div-question{
    border: 2px solid #cce0f0;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 50px;
    label{
        font-size: 18px;
        color: #3f51b5;
        font-weight: 500;
    }
    legend{
        color: #3f51b5;
        font-weight: 600;
    }
}

.stepper-que-sec{
    overflow: auto !important;
}
.first-category-question{
    width: 50%;
    display: inline-block;
    margin-bottom: 15px;
     .field>label{
        font-size: 16px !important;
        // color: #3f51b5 !important;
        font-weight: 500 !important;
    }
}

.option-lable-mcq{
    font-size: 18px;
    font-weight: 600;
}
.mcq-label-question{
    display: flex;
    align-items: center;
    margin-top: 10px;
    flex-grow: 1;
    flex-direction: column;
    margin-left: 3px;
    margin-right: 3px;
    width: 20%;
    textarea {
        min-height: 4rem !important;
        max-height: 7em !important;
        background-color: transparent !important;
        border: solid 1px !important;
    }
    .mcq-question{
        // border: 2px solid #dededf;
        border-radius: 5px;
        // margin-left: 10px;
        padding: 3px;
        padding-left: 10px;
        width: 30%;
        display: flex;
        justify-content: center;
        input[type="radio"]{
            // display: none;
            height: 22px;
            width: 100%;
            pointer-events: none;
            cursor: unset;
        }
         .field>label{
            font-size: 16px;
            font-weight: 600;
            cursor: pointer;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }

}
.stopwatch-card{
    width: 84%;
    // padding: 17px 0 0 0;
    p{
        text-align: right;
        font-weight: 600;
        font-size: 22px;
    }
}
.select-mcq-answer{
    label{
        color: $color__fill-white !important;
    }

}
.progress-bar-label{
    .MuiStepLabel-label.MuiStepLabel-alternativeLabel{
        font-size: 16px;
    }
}

.container-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom radio button */
  .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 40px;
      width: 40px;
      background-color: #fff;
      border-radius: 50%;
      border: 2px solid #3daddb;
      font-size: 30px;
      text-align: center;
      color: #333;
      align-items: center;
      display: flex;
      justify-content: center;
      font-size: 16px;
      cursor: pointer;
  }

  /* On mouse-over, add a grey background color */
  .container-label:hover input ~ .checkmark {
    background-color: #fff;
  }
  .container-label:active input ~ .checkmark {
    background-color:  #0e3764;
    color: #ffffff;
  }
  /* When the radio button is checked, add a blue background */
  .container-label input:checked ~ .checkmark {
    background-color: #0e3764;
    color: #fff;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .container-label input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
//   .container-label .checkmark:after {
//       top: 0px;
//       left: 0px;
//       width: 21px;
//       height: 21px;
//       border-radius: 100%;
//       box-shadow: 0px 0px 6px 3px #7fc9e7;
//       padding: 0px 4px;
//       font-size: 16px;
//       content: "";
//       color: #fff;
//   }
  .container-label{
      display: block;
      position: relative;
      padding-left: 52px;
      margin-bottom: 12px;
      font-size: 22px;
  }
  .agree-conditions{
    color: #979a9d;
    font-weight: 100;
    margin: 12px 0 0 0;
  }
  .txt-30{
    font-size: 30px !important;
    margin: 22px 0 0 0;
    padding: 30px 0 0 0;
  }
  .txt-26{
    font-size: 20px !important;
    margin: 22px 0 0 0;
    padding: 30px 0 0 0;
    color: #4c4c4c;
  }
  .txt-16{
     font-size: 16px;
      margin: 22px 0 0 0;
      padding: 30px 0 0 0;
     color: #4c4c4c;
  }
  .mt-40{
    margin-top: 40px !important;
  }
  .text-center{
      text-align: center !important;
  }
  .mt-20{
      margin-top: 20px !important;
  }
  .transparent-button{
    background: transparent !important;
  }
  .m-auto{
      margin: auto;
  }
  .option-label-first{
    position: absolute;
    top: 23px;
    left: 0;
  }
  .option-label-last{
    position: absolute;
    top: 23px;
    right: 0;
  }

.first-label-category-section-1{
  position: absolute;
  top: 23px;
  left: 20px;
}
.first-label-category-section-2{
  position: absolute;
  top: 23px;
  left: 20px;
}
.first-label-category-section-3{
  position: absolute;
  top: 23px;
  left: 0;
}
.first-label-category-section-4{
  position: absolute;
  top: 23px;
  left: -8px;
}
.first-label-category-section-5{
    position: absolute;
    top: 23px;
    left: 10px;
}

.last-label-category-section-1{
  position: absolute;
  top: 23px;
  right: 40px;
}
.last-label-category-section-2{
  position: absolute;
  top: 23px;
  right: -15px;
}
.last-label-category-section-3{
  position: absolute;
  top: 23px;
  right: 18px;
}
.last-label-category-section-4{
  position: absolute;
  top: 23px;
  right: 20px;
}
.last-label-category-section-5{
    position: absolute;
    top: 23px;
    right: 5px;
}
.txt-primary p{
    color: $primary_color;
    font-size: 20px;
    padding-top: 2%;
}

.progress-grid-ql {
  .RSPBprogressBar{
    width: 100%;
    height: 2px;
    line-height: 1;
    border-radius: 10px;
    position: relative;
    background-color: rgba(211, 211, 211, 0.6);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 0;
    
  }
  .RSPBstep{
    background-color: #ffffff;
    width: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
    position: absolute;
    transform: translateX(-50%);
    transition-property: all;
    transition-timing-function: ease;
    
  }
}
.txt-black{
    color: #333;
}
.txt-14{
    font-size: 14px !important;
}
.txt-15{
    font-size: 15px;
}
.user-question-stepper{
    display: flex;
    align-items: center;
}
.ml-20{
    margin-left: 20px;
}
.text-right{
    text-align: right;
}
.p-0{
    padding: 0 !important;
}
.align-center{
    align-items: center !important;
}

.checkmark-password{
    position: absolute;
    top: 12px;
    left: 11px;
    height: 25px;
    width: 25px;
    background-color: #fff;
    border-radius: 4px;
    border: 2px solid #7a7a7a;
}
.hidden-password-input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 1;
    height: 100%;
}
input:checked ~ .checkmark-password {
    background-color: #2196F3;
    text-align: center;
}
input:checked ~ .checkmark-password::after {
    content: '\2714';
    color: #fff;
    height: auto;
}
.RSPBstep{
  width: 50px;
  background: #f5f8fe;
  color: #fff;
  text-align: center;
  height: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 50%;
}
.accomplished{
    background: $color-alpha;
    color: #fff;
    text-align: center;
    height: 30px;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    width: 30px;
}
.null{
    width: 30px;
    background: #e0e0e0;
    color: #fff;
    text-align: center;
    height: 30px;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 50%;
}
.greenClass {
    background-color: #008000;
}

.pt-large{
    padding-top: 5rem;
}

.game__dashboard_box-container-assessment {
    padding: 0 36px;
    text-align: center;
    .play {
        position: absolute;
        top: 43%;
        left: 50%;
        font-size: 80px;
        right: 50%;
        color: #000;
        background: linear-gradient(#ecbd1b,#bea628)!important;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translate(-50%,-50%);
        padding: 48px 0;
        border-radius: 100%;
        opacity: 1;
        transition: opacity .5s linear;
        cursor: pointer;
    }
    img {
        border-radius: 8px;
        box-shadow: 0 5px 15px #1b75bc;
        max-width: 65%;
        margin: auto;
    }
    .video_view-link {
        margin: 4px 0 18px 0;
        color: #1b75bc;
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
        font-size: 20px;
        line-height: 3;
    }
}

.question-list_survey {
    background-color: #ffffff;
    padding: 25px;
    border-radius: 7px;
}