.pagination-inner {
    -webkit-box-shadow: 0px 2px 3px #72838e;
    box-shadow: 0px 2px 3px #72838e;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: rgba(0,0,0,.6);
    button {
        font-weight: 700;
    }
    // font-weight: 700;
    .active-pagination-footer-btn {
        font-size: 12px !important;
        background: #1b75bc !important;
        color: #fff !important;
        border: 1px solid #ebe4e4 !important;
        padding: 5px 7px !important;
        border-radius: 4px !important;
        margin: 5px 2px 5px 2px !important;
        cursor: pointer !important;

    }

    .disable-pagination-footer-btn {
        font-size: 12px !important;
        background: #d7dbdd !important;
        border: 1px solid #ebe4e4 !important;
        padding: 5px 7px !important;
        border-radius: 4px !important;
        margin: 5px 2px 5px 2px !important;
        cursor: pointer !important;
    }

    .active-footer-btn {
        font-size: 12px !important;
        background: #1b75bc !important;
        color: #fff !important;
        border: 1px solid #ebe4e4 !important;
        padding: 5px 7px !important;
        border-radius: 4px !important;
        margin: 5px 2px 5px 2px !important;
        cursor: pointer !important;
    }

    .disable-footer-btn {
        font-size: 12px !important;
        background: transparent !important;
        border: 1px solid #ebe4e4 !important;
        padding: 5px 7px !important;
        border-radius: 4px !important;
        margin: 5px 2px 5px 2px !important;
        cursor: pointer !important;
    }
}