@import "../../Core/Style/Variables.scss";
  body{
    background: #EAF1FE !important;
  }
.gamecontainer {
  // padding-top: 155px;
  padding-top: 45px;
  margin-left: 0px;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  iframe {
    border: none;
    display: block;
    width: 90%;
    height: 80%;
    margin: auto;

    canvas {
      width: 100% !important;
      height: 100% !important;
      margin: 0 auto !important;
    }
    html{
      background: #fff !important;
    }
    body{
      background: #fff !important;
    }
  }

  .main-content {
    margin-top: 10px;
    margin-right: 40px;
    margin-left: 40px;
    margin-bottom: 100px;
  }

  .m-auto {
    margin: auto;
  }

  .card__container {
    margin: 0 0px 20px 0;
  }

  .game__card-container {
    margin: 0 20px 0 0;
    width: 100%;
    min-height: 230px;
    text-align: center;
    display: flex;
    align-items: center;

    img {
      padding: 28px 0 0 0;
    }
  }

  .header {
    color: $color__fill-white  !important;
  }

  .gameheading {
    position: absolute;
    bottom: 0;
    font-size: 16px;
    font-weight: bold;
    background: $primary_color;
    color: $color__fill-white;
    width: 100%;
    padding: 8px 0;

    .gameVideoIcon {
      float: right;
      position: absolute;
      margin-right: 5px;
      cursor: pointer;
      right: 0;
    }
  }

  .game__instruction-container {
    height: calc(100vh - 158px);
    justify-content: center;
    display: flex;
    position: relative;

    button {
      position: absolute;
      bottom: 13%;
      background-color: #067A7F;
      color: #ffffff;
      padding: 7px 40px;
      border-radius: 13px;
      box-shadow: 0px 4px 4px #858585;
      border: 4px solid #f4f0f0;
      font-family: 'action_manbold' !important;
      font-size: 28px;
      font-weight: 800;
      text-shadow: 0px 4px 3px #000;
      cursor: pointer;
      @media screen and (max-width: 1024px) {
        border: 2px solid #f4f0f0;
        padding: 2px 10px !important;
        font-size: 8px !important;
        border-radius: 6px !important;
      }
    }

    .game__play-guideline {
      position: relative;
      display: flex;
      justify-content: center;
    }
  }
  .game__box-container {
    position: relative;
    margin: 0 0 18px 0;

    &:hover {
      .playbutton {
        opacity: 1;
        cursor: pointer;
      }
    }

    .playbutton {
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 50px;
      right: 50%;
      color: #000000;
      background: linear-gradient(#ecbd1b, #bea628) !important;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translate(-50%, -50%);
      padding: 30px 0;
      border-radius: 100%;
      opacity: 0;
      transition: opacity 0.5s linear;
      cursor: unset;
    }
    i{
      position: absolute;
      right: 0;
      margin: 0 8px 0 0;
    }

    img {
      border-radius: 8px;
    }

    p {
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: $primary_color;
      color: $color__fill-white;
      font-size: 15px;
      font-weight: 600;
      padding: 6px 0;
      text-align: center;
    }
  }
}

.innercontainer {
  margin-left: 225px;
}

.w-100 {
  width: 100%;
}

.udc-game-screen-color{
  background-color:#eaf1fe !important;
}