.talwind-dashboard {
    font-family: Rubik, sans-serif !important;

    h1,
    h2,
    h3,
    h4,
    h5,
    p {
        font-family: Rubik, sans-serif !important;
        margin: 0;
    }
}