.talwind-dashboard {
    font-family: Rubik, sans-serif !important;

    h1,
    h2,
    h3,
    h4,
    h5,
    p {
        font-family: Rubik, sans-serif !important;
        margin: 0;
    }

}

.text-area-border {
    border: 2px solid #7a7a7a !important;
}